import {
  ORDERS_STATUS,
  PRODUCT_ON_HODO,
  PRODUCT_ON_INVENTORY_STATUS,
  PRODUCT_TYPES,
} from "@/utils/constant";
import { computed, ref, watch } from "@vue/composition-api";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import i18n from "@/libs/i18n";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import router from "@/router";

export default function useProductsList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refProductsTable = ref(null);

  const perPage = ref(10);
  const totals = ref(0);
  const currentPage = ref(1);
  const currentStatus = ref(null);
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const outOfStockFilter = ref(null);
  const activeFilter = ref(null);
  const allOpenRows = ref([]);
  const recordDetail = ref(null);

  // Table Handlers
  const tableColumns = computed(() => {

    return [
      // { key: "id", label: "#ID", sortable: false, thClass: "text-nowrap" },

      { key: "sku", sortable: false, thClass: "text-nowrap" },
      {
        key: "primary_image",
        label: i18n.t("lbl_image"),
        sortable: false,
        thClass: "text-nowrap",
      },
      {
        key: "name",
        label: i18n.t("lbl_name"),
        sortable: false,
        thClass: "text-nowrap",
      },
      {
        key: "category",
        label: i18n.t("lbl_category"),
        sortable: false,
        thClass: "text-nowrap",
      },
      {
        key: "type",
        label: i18n.t("lbl_type"),
        sortable: false,
        thClass: "text-nowrap",
      },
      {
        key: "default_price",
        label: `${i18n.t("lbl_unit_price")} (VNĐ)`,
        sortable: false,
        thClass: "text-nowrap",
      },
      {
        key: "status",
        label: i18n.t("lbl_hodo_verified"),
        sortable: false,
        thClass: "text-nowrap text-center",
      },
      router.currentRoute?.meta?.type === PRODUCT_TYPES.PRODUCT ? {
        key: "available",
        label: i18n.t("lbl_available"),
        thClass: "text-center text-nowrap",
      } : {},
      { key: "action", label: "", thClass: "text-right text-nowrap" },

    ];
  });


  const dataMeta = computed(() => {
    const localItemsCount = refProductsTable.value
      ? refProductsTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    };
  });

  const refetchData = () => {
    refProductsTable.value.refresh();
  };

  const fetchProductsList = (ctx, callback) => {
    const userData = appUtils.getLocalUser();

    if (!userData || !userData?.supplierInfo?.id) return;

    store
      .dispatch("products/fetchProductsList", {
        keyword: searchQuery.value,
        page_size: perPage.value,
        page_num: currentPage.value,
        status: currentStatus.value,
        sortBy: sortBy.value,
        order: isSortDirDesc.value ? "desc" : "asc",
        supplier_id: userData?.supplierInfo?.id,
        type: ctx.filter?.type, // 1: Products, 2: Services
      })
      .then((response) => {
        const products = response.result?.data;
        totals.value = response.result?.page?.total;
        callback(products);
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const getProductStatus = (status) => {
    switch (status) {
      case PRODUCT_ON_HODO.UNAVAILABLE:
        return {
          classes: "bg-warning text-white",
          icon: "AlertTriangleIcon",
        };
      case PRODUCT_ON_HODO.PENDING_AVAILABLE:
        return {
          classes: "bg-light",
          icon: "MoreHorizontalIcon",
        };
      case PRODUCT_ON_HODO.AVAILABLE:
        return {
          classes: "bg-success text-white",
          icon: "CheckIcon",
        };
      case PRODUCT_ON_HODO.REJECT_AVAILABLE:
        return {
          classes: "bg-danger text-white",
          icon: "XIcon",
        };
      case PRODUCT_ON_HODO.ARCHIVE_AVAILABLE:
        return {
          classes: "bg-warning text-white",
          icon: "ArchiveIcon",
        };

      default:
        return {
          classes: "",
          icon: "",
        };
    }
  };

  const getProductType = (type) => {
    switch (type) {
      case PRODUCT_TYPES.PRODUCT:
        return {
          variant: "success",
        };
      case PRODUCT_TYPES.SERVICE:
        return {
          variant: "primary",
        };
      default:
        return {
          variant: "",
        };
    }
  };

  const viewDetailOrders = (record) => {
    // console.log(record);
    recordDetail.value = { ...record };
  };

  const addProdInInventory = async (data) => {
    const params = {
      supplier_id: data.supplier_id,
      product_id: data.id,
      qty: 1000, //TODO: update after
      is_available: PRODUCT_ON_INVENTORY_STATUS.AVAILABLE,
      is_added_inventory: 1,
    };

    return await store
      .dispatch("products/addProductIntoInventory", params)
      .then(() => {
        // fetchProductsList(null, () => {});
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const updateStatusProductById = async (data, status) => {
    const product_id = data?.id;
    const params = {
      current_status: data.status,
      status,
      supplier_id: data?.supplier_id,
      type: data?.type,
    };

    return await store
      .dispatch("products/updateProduct", {
        id: product_id,
        data: params,
      })
      .then((res) => {
        // fetchProductsList(null, () => {});
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const timeout = ref(null);

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      outOfStockFilter,
      activeFilter,
      currentStatus,
    ],
    () => {
      showTable.value = true;
      clearTimeout(timeout);
      timeout.value = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 500);
    }
  );

  return {
    currentStatus,
    timeout,
    showTable,
    fetchProductsList,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductsTable,
    refetchData,
    allOpenRows,
    ORDERS_STATUS,
    getProductStatus,
    getProductType,
    viewDetailOrders,
    recordDetail,
    addProdInInventory,
    updateStatusProductById,
  };
}
